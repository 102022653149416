import React from 'react'

interface Props {
  className?: string
  // default value: 'lg'
  size?: 'md' | 'lg'
  // default value: 'primary
  color?: 'primary' | 'secondary' | 'success'
}

const Badge: React.FC<Props> = ({ className, size, color, children }) => {
  const badgeSize = size || 'lg'
  const badgeColor = color || 'primary'

  return (
    <div className={`badge badge-${badgeSize} badge-${badgeColor} ${className || ''}`}>
      {children}
    </div>
  )
}

export default Badge
